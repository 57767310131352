<template>
  <df-modal
    disable-close
    height="480px"
    width="668px"
    :action-name="$t('salvar')"
    :disable-action="!hasOneSelectedJobTitle"
    :loading="loading"
    :title="$t('one_question_before_continue') + '...'"
    @action-click="setJobTitle"
  >
    <template #card-content>
      <div class="df-flex-l df-flex-col modal-job-title">
        <div class="df-flex-xs df-flex-col">
          <h2 class="modal__subtitle">
            {{ $t('what_is_your_job_title_today') }}?
          </h2>
          <p class="modal__description">
            {{ $t('we_want_to_know_to_improve_your_experience') }}
          </p>
        </div>
        <div class="modal__job-titles">
          <div v-show="loadingJobTitles" class="df-flex-sm">
            <df-skeleton-loader
              v-for="(_, index) in jobTitles?.length || 9"
              :key="index"
              height="44px"
              width="190px"
            />
          </div>
          <div v-show="!loadingJobTitles" class="df-flex-sm">
            <div
              v-for="jobTitle in jobTitles"
              :key="jobTitle.id"
              class="job-title"
            >
              <df-checkbox
                size="large"
                :disabled="shouldDisableCheckbox(jobTitle)"
                @change="(event) => selectJobTitles(jobTitle, event.detail)"
              >
                <span class="title__name">
                  {{ $t('job_titles.' + jobTitle.name) }}
                </span>
              </df-checkbox>
            </div>
          </div>
        </div>
      </div>
    </template>
  </df-modal>
</template>

<script>
import DfModal from '@/lib/Modal/DfModal.vue'
import { mapGetters } from 'vuex'
import '@/lib/components/Checkbox/DfCheckbox.js'
import '@/lib/components/Loader/DfSkeletonLoader.js'

export default {
  name: 'ModalJobTitle',

  components: {
    DfModal,
  },

  data() {
    return {
      jobTitles: [],
      loading: false,
      loadingJobTitles: false,
      selectedJobTitles: [],
    }
  },

  created() {
    this.listJobTitles()
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    hasOneSelectedJobTitle() {
      return this.selectedJobTitles.length === 1
    },
  },

  methods: {
    async listJobTitles() {
      this.loadingJobTitles = true
      try {
        const response = await this.$api.jobTitles.get()
        const hiddenJobTitles = [
          'manager_admin_digifarmz',
          'manager_admin_digifarmz_marketing',
          'manager_admin_digifarmz_sales',
          'manager_admin_digifarmz_operations',
        ]

        const filteredJobTitles = response.data.filter(
          (jobTitle) => !hiddenJobTitles.includes(jobTitle.name)
        )

        this.jobTitles = filteredJobTitles
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingJobTitles = false
      }
    },
    selectJobTitles(jobTitle, checked) {
      if (checked) {
        this.selectedJobTitles.push(jobTitle.id)
      } else {
        const index = this.selectedJobTitles.findIndex(
          (job) => job === jobTitle.id
        )
        this.selectedJobTitles.splice(index, 1)
      }
    },
    async setJobTitle() {
      this.loading = true
      try {
        await this.$api.jobTitles.set(
          this.currentUser?.id,
          this.selectedJobTitles[0]
        )

        this.$root.$emit('notify', 'success', this.$t('succesfuly_saved'))

        this.$emit('close')
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    shouldDisableCheckbox(jobTitle) {
      return (
        this.hasOneSelectedJobTitle &&
        !this.selectedJobTitles.includes(jobTitle.id)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-job-title {
  .modal__subtitle {
    @include heading-x-small;
    white-space: normal;
  }
  .modal__description {
    @include paragraph-medium;
    color: $color-text-neutral;
  }
  .modal__job-titles {
    overflow-y: auto;
    max-height: 500px;
    padding: $spacing-l;
    border-radius: $border-radius-sm;
    background: $color-background-light;

    .job-title {
      padding: $spacing-m;
      border-radius: $border-radius-m;
      background-color: $color-background-disabled;

      .title__name {
        @include label-small;
        color: $color-text-neutral;
      }
    }
  }
}
</style>
