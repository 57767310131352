import colors from '@/assets/styles/__colors.module.scss'

class DfCheckbox extends HTMLElement {
  static get observedAttributes() {
    return ['disabled', 'size']
  }

  attributeChangedCallback(name, _, newValue) {
    if (name === 'disabled') {
      this._disabled = newValue === 'true'

      if (this._disabled) {
        this.disableComponent()
      } else {
        this.ableComponent()
      }

      this.updateSlotTextColor()
    }

    if (name === 'size') {
      const checkboxSizeObj = {
        small: '12px',
        medium: '16px',
        large: '20px',
      }
      const iconSizeObj = {
        small: '8px',
        medium: '12px',
        large: '16px',
      }

      const checkbox = this.shadowRoot.querySelector('#checkbox')
      checkbox.style.setProperty('--checkbox-height', checkboxSizeObj[newValue])
      checkbox.style.setProperty('--checkbox-width', checkboxSizeObj[newValue])
      checkbox.style.setProperty('--checkbox-font-size', iconSizeObj[newValue])
    }
  }

  connectedCallback() {
    if (this._listenersAdded) return
    this._listenersAdded = true

    const checkbox = this.shadowRoot.querySelector('#checkbox')

    this._boundContainerClick = (event) => {
      if (event.target !== checkbox) {
        checkbox.checked = !checkbox.checked
        this.changeSelection()
      }
    }

    this._boundCheckboxChange = () => {
      this.changeSelection()
    }

    if (this._disabled) {
      this.disableComponent()
    } else {
      this.ableComponent()
    }
  }

  disconnectedCallback() {
    if (!this._listenersAdded) return
    this.disableComponent()
    this._listenersAdded = false
  }

  changeSelection() {
    const checkbox = this.shadowRoot.querySelector('#checkbox')
    this.dispatchEvent(
      new CustomEvent('change', {
        detail: checkbox.checked,
        bubbles: true,
        composed: true,
      })
    )
  }

  updateSlotTextColor() {
    const slot = this.shadowRoot.querySelector('slot')
    const assignedNodes = slot.assignedNodes()

    assignedNodes.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        if (this._disabled) {
          this.applyTextColor(node)
        } else {
          this.restoreTextColor(node)
        }
      }
    })
  }

  applyTextColor(node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const textElements = ['h1', 'h2', 'h3', 'h4', 'h5', 'p', 'span']

      if (textElements.includes(node.nodeName.toLowerCase())) {
        if (!this._originalColors.has(node)) {
          const originalColor = getComputedStyle(node).color
          this._originalColors.set(node, originalColor)
        }
        node.style.color = colors.colorTextDisabled
      }

      node.childNodes.forEach((childNode) => {
        this.applyTextColor(childNode)
      })
    }
  }

  restoreTextColor(node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const textElements = ['h1', 'h2', 'h3', 'h4', 'h5', 'p', 'span']

      if (textElements.includes(node.nodeName.toLowerCase())) {
        if (this._originalColors.has(node)) {
          const originalColor = this._originalColors.get(node)
          node.style.color = originalColor
          this._originalColors.delete(node)
        }
      }

      node.childNodes.forEach((childNode) => {
        this.restoreTextColor(childNode)
      })
    }
  }

  ableComponent() {
    const checkbox = this.shadowRoot.querySelector('#checkbox')
    const container = this.shadowRoot.querySelector('.df-checkbox')

    container.classList.remove('disabled')
    checkbox.classList.remove('disabled')
    checkbox.disabled = false

    if (this._boundContainerClick && this._boundCheckboxChange) {
      container.addEventListener('click', this._boundContainerClick)
      checkbox.addEventListener('change', this._boundCheckboxChange)
    }
  }

  disableComponent() {
    const checkbox = this.shadowRoot.querySelector('#checkbox')
    const container = this.shadowRoot.querySelector('.df-checkbox')

    container.classList.add('disabled')
    checkbox.classList.add('disabled')
    checkbox.disabled = true

    if (this._boundContainerClick && this._boundCheckboxChange) {
      container.removeEventListener('click', this._boundContainerClick)
      checkbox.removeEventListener('change', this._boundCheckboxChange)
    }
  }

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
    this._listenersAdded = false
    this._disabled = false
    this._originalColors = new Map()

    this.shadowRoot.innerHTML = `
      <style lang="scss" scoped>
        .df-checkbox {
          display: flex;
          gap: var(--spacing-m);
          align-items: center;
          flex-wrap: nowrap;
          cursor: pointer;
        }
        .checkbox {
          max-height: var(--checkbox-height, 16px);
          max-width: var(--checkbox-width, 16px);
          min-height: var(--checkbox-height, 16px);
          min-width: var(--checkbox-width, 16px);
          border-radius: var(--border-radius-sm);
          border: 1px solid var(--color-border-dark);
          margin: 0px;
          appearance: none;
          -webkit-appearance: none;
          display: inline-block;
          position: relative;
        }
        .checkbox.disabled {
          background-color: var(--color-background-disabled);
        }
        input[type="checkbox"] {
          appearance: none;
          -webkit-appearance: none;
          background-color: var(--color-background-light);
        }
        input[type="checkbox"]:not(.disabled):hover {
          background-color: var(--color-background-primary-lightest);
          border-color: var(--color-border-primary);
          cursor: pointer;
        }
        .df-checkbox:not(.disabled):hover .checkbox {
          background-color: var(--color-background-primary-lightest);
          border-color: var(--color-border-primary);
        }
        input[type="checkbox"]:checked {
          background-color: var(--color-background-primary);
          border-color: var(--color-border-primary);
        }
        .df-checkbox:not(.disabled):hover input[type="checkbox"]:checked {
          background-color: var(--color-background-primary);
          border-color: var(--color-border-primary);
        }
        .checkbox:checked::after {
          content: '✔';
          color: var(--color-icon-white);
          font-size: var(--checkbox-font-size, 12px);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .disabled {
          cursor: auto;
        }
      </style>
      <div class="df-checkbox">
        <input id="checkbox" class="checkbox" type="checkbox">
        <slot></slot>
      </div>
    `
  }
}

customElements.define('df-checkbox', DfCheckbox)
